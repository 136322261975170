import React, { Suspense, lazy } from 'react';
import { Spin } from 'antd';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Materials from './materials';
import StudentsRoutes from './students';
import CoursesRoutes from './courses';
import TicketsRoutes from './tickets';
import CouponsRoutes from './coupons';
import AccessCodesRoutes from './accesscodes';
import UserTokensRoutes from './userTokens';
import ProductsRoutes from './products';
import CartRoutes from './cart';
import OrdersRoutes from './orders';
import RewardsRoutes from './rewards';
import QuizRoutes from './quiz';
import QuizQuestionsRoutes from './quizquestions';
import ExercisesRoutes from './excercises';
import AssignedExercisesSettingsRoutes from './assignedExcercisesSettings';
import ExerciseQueueRoutes from './exercisesQueue';
import QuestionDatabaseRoutes from './questionDatabase';
import ModulesRoutes from './modules';
import RatingsRoutes from './ratings';

import AssignedExercisesStatsRoutes from './assignedExcercisesStats';

import VerifyMaterialsRoutes from './verifyMaterials';
import MyVerifyMaterialsRoutes from './myVerifyMaterials';
import OverdueToVerifyMaterialsRoutes from './overdueToVerifyMaterials';
import RecentVerifiedMaterialsRoutes from './recentVerifiedMaterials';

import CartsRoutes from './carts';
import ShopOrdersRoutes from './shopOrders';
import ShopPromoCodesRoutes from './shopPromoCodes';
import ShopDepotRoutes from './shopDepot';
import ShopProductsRoutes from './shopProducts';
import SubscriptionsRoutes from './subscriptions';
import SubscriptionInvoicesRoutes from './subscriptionInvoices';
import MultimediaRoutes from './multimedia';
import MaterialVideosRoutes from './materialVideos';
import CrmRoutes from './crm';
import ShopRoutes from './shopRoutes';
import TicketsCategoriesRoutes from './ticketsCategories';
import RecentTeachersComments from '../container/materials/overview/RecentTeachersComments';
import withAdminLayout from '../layout/withAdminLayout';
import NegativePoints from '../container/materials/overview/RecentNegativePoints';

const MyProfileSettings = lazy(() => import('../container/myProfile'));
const Changelog = lazy(() => import('../container/changelog/'));

const Routes = () => {
  const { user, courses, studentCourses } = useSelector(state => {
    return {
      user: state.user.user,
      courses: state.courses.courses,
      studentCourses: state.students.studentCourses,
    };
  });
  const matchCourses = user.role === 'STUDENT' ? studentCourses : courses;
  const firstCourseId =
    matchCourses.length > 0 && user.role === 'STUDENT'
      ? matchCourses[0].courseId
      : matchCourses.length > 0 &&
        (user.role === 'ADMIN' ||
          user.role === 'TEACHER' ||
          user.role === 'SENIOR-TEACHER' ||
          user.role === 'QUALITY-ASSURANCE' ||
          user.role === 'SHIPMENT-ASSISTANT')
      ? matchCourses[0]._id
      : '';

  const DashboardRedirect = () => {
    return <Redirect to={`/courses/${firstCourseId}/dashboard`} />;
  };

  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route exact path="/" component={DashboardRedirect} />
        <Route
          path="/materials"
          render={() =>
            (!user.isActiveSubscriptionPayment && user.paymentType === 'PACKAGES_V1') || user.role !== 'STUDENT' ? (
              <Materials />
            ) : (
              <Redirect to="/settings/profile" />
            )
          }
        />
        <Route path="/students" component={StudentsRoutes} />
        <Route path="/courses" component={CoursesRoutes} />
        <Route path="/coupons" component={CouponsRoutes} />
        <Route path="/accesscodes" component={AccessCodesRoutes} />
        <Route path="/multimedia" component={MultimediaRoutes} />
        <Route path="/material-videos" component={MaterialVideosRoutes} />
        <Route path="/user-tokens" component={UserTokensRoutes} />
        <Route path="/settings" component={MyProfileSettings} />
        <Route path="/ticket/categories" component={TicketsCategoriesRoutes} />
        <Route path="/tickets/:page" component={TicketsRoutes} />
        <Route path="/verify" component={VerifyMaterialsRoutes} />
        <Route path="/my-verify" component={MyVerifyMaterialsRoutes} />
        <Route path="/verify/overdue" component={OverdueToVerifyMaterialsRoutes} />
        <Route path="/exercises/settings" component={AssignedExercisesSettingsRoutes} />
        <Route path="/exercises/stats" component={AssignedExercisesStatsRoutes} />
        <Route path="/exercises/recent" component={RecentVerifiedMaterialsRoutes} />
        <Route path="/exercises/comments" component={RecentTeachersComments} />
        <Route path="/exercises/negative-points" component={NegativePoints} />
        <Route path="/exercises" component={ExercisesRoutes} />
        <Route path="/carts" component={CartsRoutes} />
        <Route path="/exercises-queue" component={ExerciseQueueRoutes} />
        <Route path="/quizquestions" component={QuizQuestionsRoutes} />
        <Route path="/products" component={ProductsRoutes} />
        <Route path="/ratings" component={RatingsRoutes} />
        <Route path="/crm" component={CrmRoutes} />
        <Route
          path="/orders"
          render={() =>
            user.isActivePaymentForPackages || user.role !== 'STUDENT' || user.paymentType === 'PACKAGES_V2' ? (
              <OrdersRoutes />
            ) : (
              <Redirect to="/settings/profile" />
            )
          }
        />
        <Route
          path="/shop"
          render={() =>
            user.paymentType === 'PACKAGES_V2' || user.paymentType === 'PACKAGES_V1' ? (
              <ShopRoutes />
            ) : (
              <Redirect to="/" />
            )
          }
        />
        <Route
          path="/cart"
          render={() =>
            user.paymentType === 'PACKAGES_V2' || user.paymentType === 'PACKAGES_V1' ? (
              <CartRoutes />
            ) : (
              <Redirect to="/settings/profile" />
            )
          }
        />
        <Route path="/changelog" component={Changelog} />
        <Route path="/quiz" component={QuizRoutes} />
        <Route path="/rewards" component={RewardsRoutes} />
        <Route path="/shop-external/orders" component={ShopOrdersRoutes} />
        <Route path="/shop-external/depot" component={ShopDepotRoutes} />
        <Route path="/shop-external/products" component={ShopProductsRoutes} />
        <Route path="/shop-external/promocodes" component={ShopPromoCodesRoutes} />
        <Route path="/question-database" component={QuestionDatabaseRoutes} />
        <Route path="/subscriptions" component={SubscriptionsRoutes} />
        <Route path="/subscriptions/invoices" component={SubscriptionInvoicesRoutes} />
        <Route path="/modules" component={ModulesRoutes} />
      </Suspense>
    </Switch>
  );
};

export default withAdminLayout(Routes);
