import React, { useState, useEffect } from 'react';
import moment from 'moment';

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState({});
  const endDate = moment('2024-12-01T23:59:59');

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment();
      const duration = moment.duration(endDate.diff(now));

      const days = String(Math.floor(duration.asDays())).padStart(2, '0');
      const hours = String(duration.hours()).padStart(2, '0');
      const minutes = String(duration.minutes()).padStart(2, '0');
      const seconds = String(duration.seconds()).padStart(2, '0');

      setTimeLeft({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(interval);
  }, [endDate]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        color: '#FFFFFF',
        maxHeight: '30px',
        minHeight: '30px',
        alignItems: 'center',
        marginLeft: '11px',
        gap: '1px',
        lineHeight: '40px',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          color: '#FFFFFF',
          backgroundColor: '#47536E',
          borderRadius: '2px',
          maxHeight: '23px',
          minHeight: '23px',
          width: '20px',
        }}
      >
        <div
          style={{
            fontSize: '10px',
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxHeight: '10px',
            marginBottom: '-1px',
          }}
          className="time-left-text"
        >
          {timeLeft.days}
        </div>
        <div
          style={{
            fontSize: '5px',
            fontWeight: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxHeight: '10px',
          }}
        >
          DNI
        </div>
      </div>
      <div
        style={{
          fontSize: '10px',
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxHeight: '10px',
        }}
      >
        :
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          color: '#FFFFFF',
          backgroundColor: '#47536E',
          borderRadius: '2px',
          maxHeight: '23px',
          minHeight: '23px',
          width: '20px',
        }}
      >
        <div
          style={{
            fontSize: '10px',
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxHeight: '10px',
            marginBottom: '-1px',
          }}
          className="time-left-text"
        >
          {timeLeft.hours}
        </div>
        <div
          style={{
            fontSize: '5px',
            fontWeight: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxHeight: '10px',
          }}
        >
          GODZ.
        </div>
      </div>
      <div
        style={{
          fontSize: '10px',
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxHeight: '10px',
        }}
      >
        :
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          color: '#FFFFFF',
          backgroundColor: '#47536E',
          borderRadius: '2px',
          maxHeight: '23px',
          minHeight: '23px',
          width: '20px',
        }}
      >
        <div
          style={{
            fontSize: '10px',
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxHeight: '10px',
            marginBottom: '-1px',
          }}
          className="time-left-text"
        >
          {timeLeft.minutes}
        </div>
        <div
          style={{
            fontSize: '5px',
            fontWeight: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxHeight: '10px',
          }}
        >
          MIN.
        </div>
      </div>
      <div
        style={{
          fontSize: '10px',
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxHeight: '10px',
        }}
      >
        :
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          color: '#FFFFFF',
          backgroundColor: '#47536E',
          borderRadius: '2px',
          maxHeight: '23px',
          minHeight: '23px',
          width: '20px',
        }}
      >
        <div
          style={{
            fontSize: '10px',
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxHeight: '10px',
            marginBottom: '-1px',
          }}
          className="time-left-text"
        >
          {timeLeft.seconds}
        </div>
        <div
          style={{
            fontSize: '5px',
            fontWeight: 400,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxHeight: '10px',
          }}
        >
          SEK.
        </div>
      </div>
    </div>
  );
};

export default Countdown;
