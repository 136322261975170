import React, { useEffect, useState, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FeatherIcon from 'feather-icons-react';
import { notification } from 'antd';
import { InfoWraper, UserDropDwon } from './auth-info-style';
import ShoppingBag from './shoppingbag';
// import Notification from './notification';
import Settings from './settings';
// import Support from './support';
import { Avatar } from '../../avatar/Avatar';
import { Popover } from '../../popup/popup';
import { logOutToken } from '../../../ducks/auth';
import { clearUser, healthCheck, getUser, notify } from '../../../ducks/user';

const HealthChecker = () => {
  const [intervalCount, setIntervalCount] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(state => {
    return {
      user: state.user.user,
    };
  });
  let healthCheckInterval = null;
  const healthCheckRequest = useCallback(async () => {
    try {
      const response = await healthCheck();
      if (response.statusCode === 401) {
        await notify(`Zalogowano na innym urządzeniu lub nastąpiło conocne wylogowanie!`, user.email);
        notification.success({
          message: `Zalogowano na innym urządzeniu lub nastąpiło conocne wylogowanie!`,
          description: 'Wylogowano.',
          placement: 'bottomRight',
        });
        setIntervalCount(0);
        logOutToken();
        await dispatch(clearUser());
        history.push('/');
      }

      // every 10sec, 6 * 10sec = 60sec * 5 = 5min
      if (response.status !== 200 && response.statusCode !== 200 && intervalCount > 6 * 5) {
        await notify(`Problem z internetem!`, user.email);
        notification.success({
          message: `Problem z internetem!`,
          description: 'Wylogowano.',
          placement: 'bottomRight',
        });
        setIntervalCount(0);
        logOutToken();
        await dispatch(clearUser());
        history.push('/');
      }
      if (response.status !== 200 && response.statusCode !== 200 && intervalCount <= 6 * 5) {
        setIntervalCount(prev => prev + 1);
      }
      if (response.status === 200) {
        setIntervalCount(0);
      }
    } catch (error) {
      await setIntervalCount(prev => prev + 1);
      // every 10sec, 6 * 10sec = 60sec * 5 = 5min
      if (intervalCount > 6 * 5) {
        await notify(`Brak internetu!`, user.email);
        notification.success({
          message: `Brak internetu!`,
          description: 'Wylogowano.',
          placement: 'bottomRight',
        });
        setIntervalCount(0);
        logOutToken();
        await dispatch(clearUser());
        history.push('/');
      }
      if (intervalCount <= 6 * 5) {
        setIntervalCount(prev => prev + 1);
      }
    }
  }, [dispatch, history, intervalCount, user.email]);

  useEffect(() => {
    healthCheckInterval = setInterval(() => healthCheckRequest(), 10000);
    return () => clearInterval(healthCheckInterval);
  }, [intervalCount]);

  return null;
};

const AuthInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(state => state.user);

  const SignOut = async e => {
    await e.preventDefault();
    await dispatch(logOutToken());
    await dispatch(clearUser());
    history.push('/');
  };
  const getRole = role => {
    switch (role) {
      case 'ADMIN':
        return 'ADMIN';
      case 'TEACHER':
        return 'TEACHER';
      case 'SENIOR-TEACHER':
        return 'TEACHER';
      case 'QUALITY-ASSURANCE':
        return 'QUALITY-ASSURANCE';
      case 'SHIPMENT-ASSISTANT':
        return 'SHIPMENT-ASSISTANT';
      default:
        return 'STUDENT';
    }
  };

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  const roleWithCapitalizedFirstLetter =
    getRole(user.role) === 'STUDENT'
      ? 'Uczeń'
      : getRole(user.role)
          .charAt(0)
          .toUpperCase() +
        getRole(user.role)
          .slice(1)
          .toLowerCase();

  const userContent = (
    <UserDropDwon className="user-dropdown-wrapper">
      <div className="user-dropdwon">
        <figure className="user-dropdwon__info">
          <div className="user-dropdown-avatar-wrapper">
            <Avatar userId={user._id} firstName={user.firstName} lastName={user.lastName} size={30} />
          </div>
          <figcaption style={{ marginLeft: '-5px', marginBottom: '-2px' }}>
            <div className="user-name-and-surname" as="h5">{`${user.firstName}  ${user.lastName}`}</div>
            <p className="user-role">{roleWithCapitalizedFirstLetter}</p>
          </figcaption>
        </figure>
        <ul className={user.isActive ? 'user-dropdwon__links' : 'user-dropdwon__links blocked'}>
          <li>
            <Link to={user.isActive ? '/settings/profile' : ''}>
              <FeatherIcon icon={user.isActive ? 'user' : 'lock'} /> Moje konto
            </Link>
          </li>
        </ul>
        {(user.paymentType === 'PACKAGES_V2' || user.paymentType === 'PACKAGES_V1') && (
          <ul className="user-dropdwon__links">
            <li>
              <Link to={user.isActive ? '/shop/orders' : ''}>
                <FeatherIcon icon={user.isActive ? 'file' : 'lock'} /> Zamówienia
              </Link>
            </li>
          </ul>
        )}
        <ul className="user-dropdwon__links">
          <li>
            <Link to="/tickets/all">
              <FeatherIcon icon="flag" /> Moje zgłoszenia
            </Link>
          </li>
        </ul>
        <Link className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
          <FeatherIcon icon="log-out" strokeWidth={3} /> Wyloguj
        </Link>
      </div>
    </UserDropDwon>
  );
  return (
    <InfoWraper id={user.isActivePaymentForPackages || user.paymentType === 'PACKAGES_V2' ? '' : 'basket-not-active'}>
      {(user.isActivePaymentForPackages || user.paymentType === 'PACKAGES_V2') && (
        <ShoppingBag isActive={user.isActive} />
      )}
      {/* <Notification /> */}

      {/* <Settings user={user} /> */}
      <HealthChecker />
      {/* <Support /> */}
      <div className="nav-author">
        <Popover placement="bottomRight" content={userContent} action="click">
          <Link to="#" className="head-example">
            <Avatar
              userId={user._id}
              size={25}
              firstName={user.firstName}
              lastName={user.lastName}
              topbarAvatar
              className="topBar-avatar-class"
            />
          </Link>
        </Popover>
      </div>
    </InfoWraper>
  );
};

export default AuthInfo;
