import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const ExercisesQueue = lazy(() => import('../container/exercisesQueue'));
const DetailedStatisticsPage = lazy(() => import('../container/exercisesQueue/overview/DetailedJobsQueueStats'));

const ExercisesQueueRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={ExercisesQueue} />
      <Route exact path={`${path}/detailed-stats`} component={DetailedStatisticsPage} />
    </Switch>
  );
};

export default ExercisesQueueRoutes;
